(function ($, w, d, undefined) {
  $('.dealer-achievements-wrapper').lightGallery({
    selector: '.dealer-carousel-item',
    exThumbImage: 'data-exthumbimage'
  })

  $('.open-dealer-lg').click((e) => {
    e.preventDefault()
    $('.dealer-achievements-wrapper .col-md-4:first-child .dealer-carousel-item').click()
  })
}($, window, document))
