document.addEventListener('rsq.service.googleadsgtag.ready', () => {
  const contactBtn = document.querySelector('.single-piscines-dealer .dealer-schedules-footer .toggle-contact')
  if (contactBtn) {
    contactBtn.addEventListener('click', () => {
      // Send Google Ads consversion
      if (typeof window.gtag !== 'undefined') {
        gtag('event', 'conversion', {
          // eslint-disable-next-line camelcase
          send_to: 'AW-1034033135/kGtYCOLbsrIYEO-viO0D'
        })
      }
    })
  }
})
